import { Injectable } from '@angular/core';
import { ApiGatewayService } from '@wam/authentication';
import { Observable, of } from 'rxjs';
import {
  ConfigurationCreateInput,
  ConfigurationScope,
  ConfigurationValue,
  IConfiguration,
  IConfigurationItem,
} from '@wap/configuration';
import { Store } from '@ngrx/store';
import { mergeMap } from 'rxjs/operators';
import { State, User } from '@wam/shared';
import { isNil } from 'lodash-es';
import { WithAuthService } from '@app/shared/services/with-auth.service';

@Injectable({
  providedIn: 'root',
})
export class ConfigurationDataService extends WithAuthService {
  constructor(
    protected store: Store<State>,
    private apiGatewayService: ApiGatewayService,
  ) {
    super(store);
  }

  load(): Observable<IConfiguration> {
    return this.withUser().pipe(
      mergeMap((user: User) => {
        if (isNil(user)) {
          return of({});
        } else {
          return this.apiGatewayService.get<IConfiguration>(
            `configuration/v2/apps/${user?.application}/configurations`,
            {
              keyForAttribute: (key) => key,
              refreshOnExpiredToken: false,
            },
          );
        }
      }),
    );
  }

  save(
    key: string,
    status: boolean,
    organization?: string,
    school?: string,
  ): Observable<{ data: { attributes: IConfiguration } }> {
    return this.withUser().pipe(
      mergeMap((user: User) => {
        const body: ConfigurationCreateInput = {
          key,
          status,
          organization: organization ?? user.organization,
        };
        if (school) {
          body.school = school;
        }
        return this.apiGatewayService.simplePost<{ data: { attributes: IConfiguration } }>(
          `configuration/v2/apps/${user?.application}/configurations`,
          body,
        );
      }),
    );
  }

  saveValue(
    key: string,
    value: unknown,
    status: boolean,
  ): Observable<{ data: { attributes: IConfiguration } }> {
    return this.withUser().pipe(
      mergeMap((user: User) => {
        const body = {
          key,
          status,
          value,
          organization: user.organization,
        };
        return this.apiGatewayService.simplePost<{ data: { attributes: IConfiguration } }>(
          `configuration/v2/apps/${user?.application}/configurations`,
          body,
        );
      }),
    );
  }

  getGlobal(key: string): Observable<IConfigurationItem> {
    return this.withUser().pipe(
      mergeMap((user: User) =>
        this.apiGatewayService.get<IConfigurationItem>(
          `configuration/v2/apps/global/configurations/find/${key}`,
        ),
      ),
    );
  }

  get(key: string): Observable<IConfigurationItem> {
    return this.withUser().pipe(
      mergeMap((user: User) =>
        this.apiGatewayService.get<IConfigurationItem>(
          `configuration/v2/apps/${user?.application}/configurations/find/${key}`,
        ),
      ),
    );
  }

  savePrivate(
    key: string,
    value: ConfigurationValue,
  ): Observable<{ data: { attributes: IConfiguration } }> {
    return this.withUser().pipe(
      mergeMap((user: User) => {
        const organization = user.organization ?? this.getDefaultOrganization(user.application);
        const userId = user.uuid ?? user.cognitoId;
        const body: ConfigurationCreateInput = {
          key,
          value,
          organization,
          user: userId,
          scope: ConfigurationScope.PRIVATE,
          status: true,
        };
        return this.apiGatewayService.simplePut<{ data: { attributes: IConfiguration } }>(
          `configuration/v2/apps/${user.application}/orgs/${organization}/users/${userId}/configurations`,
          body,
        );
      }),
    );
  }

  findForOrganization(
    application: string,
    organization: string,
    key: string,
  ): Observable<IConfigurationItem> {
    return this.apiGatewayService.get<IConfigurationItem>(
      `configuration/v2/apps/${application}/orgs/${organization}/configurations/find/${key}`,
    );
  }

  saveGlobalUILock() {
    return this.withUser().pipe(
      mergeMap(({ organization }) => {
        const body = {
          organization,
          key: 'organizationUpdatesLocked',
          value: true,
          status: true,
          scope: ConfigurationScope.PRIVATE,
        };
        return this.apiGatewayService.simplePost<{ data: { attributes: IConfiguration } }>(
          `configuration/v2/apps/global/configurations`,
          body,
        );
      }),
    );
  }

  private getDefaultOrganization(application: string): string | undefined {
    switch (application) {
      case 'waterford-upstart':
        return 'upstart';
      case 'waterford-parent':
        return 'mentor';
      case 'waterford-admin':
        return 'admin';
      default:
        return undefined;
    }
  }
}
